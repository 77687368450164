// import homeIcon from '../assets/images/home.svg';
// import componentsIcon from '../assets/images/components.svg';
// import foundationIcon from '../assets/images/foundation.svg';
// import templatesIcon from '../assets/images/templates.svg';
// import productsIcon from '../assets/images/products.svg';
// import resourcesIcon from '../assets/images/resources.svg';
// import onboardingIcon from '../assets/images/onboarding.svg';

export const DESIGN_SYSTEM_ROUTES = [
  '/magenta-design-system',
  '/magenta-design-system/home',
  '/magenta-design-system/about',
  '/magenta-design-system/components',
  '/magenta-design-system/components/accordion',
  '/magenta-design-system/components/brand-header-navigation',
  '/magenta-design-system/components/breadcrumb',
  '/magenta-design-system/components/button',
  '/magenta-design-system/components/divider',
  '/magenta-design-system/components/dropdown',
  '/magenta-design-system/components/flyout-menu',
  '/magenta-design-system/components/icon',
  '/magenta-design-system/components/footer',
  '/magenta-design-system/components/link',
  '/magenta-design-system/components/datepicker',
  '/magenta-design-system/components/list',
  '/magenta-design-system/components/logo',
  '/magenta-design-system/components/modal',
  '/magenta-design-system/components/pagination',
  '/magenta-design-system/components/progress-bar',
  '/magenta-design-system/components/card',
  '/magenta-design-system/components/radio-button-group',
  '/magenta-design-system/components/radio-button',
  '/magenta-design-system/components/rating-stars',
  '/magenta-design-system/components/sidebar-navigation',
  '/magenta-design-system/components/checkbox-group',
  '/magenta-design-system/components/checkbox',
  '/magenta-design-system/components/datagrid',
  '/magenta-design-system/components/slider',
  '/magenta-design-system/components/switch',
  '/magenta-design-system/components/loading-spinner',
  '/magenta-design-system/components/tab-navigation',
  '/magenta-design-system/components/tag',
  '/magenta-design-system/components/table',
  '/magenta-design-system/components/textfield',
  '/magenta-design-system/components/textarea',
  '/magenta-design-system/beta-components/tooltip',
  '/magenta-design-system/beta-components/toggle-group',
  '/magenta-design-system/beta-components/notification-toast',
  '/magenta-design-system/beta-components/notification-badge',
  '/magenta-design-system/beta-components/callout',
  '/magenta-design-system/beta-components/notification-banner',
  '/magenta-design-system/beta-components/notification-message'
];

export const PAGES = {
  MAGENTA_DESIGN_SYSTEM: {
    path: '/magenta-design-system'
  },
  HOME: {
    path: '/magenta-design-system/home',
    leftNavName: 'Home',
    showArrow: false,
    leftNavPriority: 1
  },
  ABOUT: {
    path: '/magenta-design-system/about',
    leftNavName: 'About Scale',
    showArrow: false,
    leftNavPriority: 2
  },
  COMPONENTS: {
    path: '/magenta-design-system/components',
    leftNavName: 'Components',
    showArrow: true,
    isHeading: true,
    leftNavPriority: 3,
    subPages: {
      ACCORDION: {
        path: '/magenta-design-system/components/accordion',
        subPageName: 'Accordion',
        subPagePriority: 1,
        subSections: [
          {
            path: '#accordion-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#accordion-dependent',
            subSectionName: 'Dependent',
            subSectionPriority: 2
          },
          {
            path: '#accordion-expanded',
            subSectionName: 'Expanded',
            subSectionPriority: 3
          },
          {
            path: '#accordion-heading',
            subSectionName: 'Heading Level',
            subSectionPriority: 4
          }
        ]
      },
      BRANDHEADERNAVIGATION: {
        path: '/magenta-design-system/components/brand-header-navigation',
        subPageName: 'Brand Header and Navigation',
        subPagePriority: 2,
        subSections: [
          {
            path: '#brand-header-navigation-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#brand-header-navigation-custom-main-navigation',
            subSectionName: 'Custom Main Navigation',
            subSectionPriority: 2
          },
          {
            path: '#brand-header-navigation-custom-icon-navigation',
            subSectionName: 'Custom Icon Navigation',
            subSectionPriority: 3
          },
          {
            path: '#brand-header-navigation-custom-sector-navigation',
            subSectionName: 'Custom Sector Navigation',
            subSectionPriority: 4
          },
          {
            path: '#brand-header-navigation-custom-addon-navigation',
            subSectionName: 'Custom Addon Navigation',
            subSectionPriority: 5
          },
          {
            path: '#brand-header-navigation-custom-logo',
            subSectionName: 'Custom Logo',
            subSectionPriority: 5
          },
          {
            path: '#brand-header-custom-mobile-menu',
            subSectionName: 'Custom Mobile Menu',
            subSectionPriority: 5
          },
          {
            path: '#brand-header-navigation-standalone-header',
            subSectionName: 'Standalone Header',
            subSectionPriority: 5
          }
        ]
      },
      BREADCRUMB: {
        path: '/magenta-design-system/components/breadcrumb',
        subPageName: 'Breadcrumb',
        subPagePriority: 3,
        subSections: [
          {
            path: '#breadcrumb-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          }
        ]
      },
      BUTTON: {
        path: '/magenta-design-system/components/button',
        subPageName: 'Button',
        subPagePriority: 4,
        subSections: [
          {
            path: '#button-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#button-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 2
          },
          {
            path: '#button-secondary-disabled',
            subSectionName: 'Secondary Disabled',
            subSectionPriority: 3
          },
          {
            path: '#button-with-icon-before',
            subSectionName: 'With Icon Before',
            subSectionPriority: 4
          },
          {
            path: '#button-with-icon-after',
            subSectionName: 'With Icon After',
            subSectionPriority: 5
          },
          {
            path: '#button-with-icon-only',
            subSectionName: 'Icon Only',
            subSectionPriority: 6
          },
          {
            path: '#button-link',
            subSectionName: 'Link',
            subSectionPriority: 7
          },
          {
            path: '#button-link-download',
            subSectionName: 'Link Download',
            subSectionPriority: 8
          },
          {
            path: '#button-external-link',
            subSectionName: 'External link',
            subSectionPriority: 9
          },
          {
            path: '#button-small-standard',
            subSectionName: 'Small Standard',
            subSectionPriority: 10
          },
          {
            path: '#button-small-secondary',
            subSectionName: 'Small Secondary',
            subSectionPriority: 11
          },
          {
            path: '#button-small-disabled',
            subSectionName: 'Small Disabled',
            subSectionPriority: 12
          },
          {
            path: '#button-small-secondary-disabled',
            subSectionName: 'Small Secondary Disabled',
            subSectionPriority: 13
          },
          {
            path: '#button-small-icon-before',
            subSectionName: 'Small Icon Before',
            subSectionPriority: 14
          },
          {
            path: '#button-small-icon-after',
            subSectionName: 'Small Icon After',
            subSectionPriority: 15
          },
          {
            path: '#button-small-icon-only',
            subSectionName: 'Small Icon Only',
            subSectionPriority: 16
          },
          {
            path: '#button-small-link',
            subSectionName: 'Small Link',
            subSectionPriority: 17
          },
          {
            path: '#button-small-link-download',
            subSectionName: 'Small Link Download',
            subSectionPriority: 18
          }
        ]
      },
      CARD: {
        path: '/magenta-design-system/components/card',
        subPageName: 'Card',
        subPagePriority: 5,
        subSections: [
          {
            path: '#card-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#card-with-link',
            subSectionName: 'With Link',
            subSectionPriority: 2
          },
          {
            path: '#card-with-image',
            subSectionName: 'With Image',
            subSectionPriority: 3
          },
          {
            path: '#card-with-further-functions',
            subSectionName: 'With Further Functions',
            subSectionPriority: 4
          }
        ]
      },
      CHECKBOXGROUP: {
        path: '/magenta-design-system/components/checkbox-group',
        subPageName: 'Checkbox Group',
        subPagePriority: 6,
        subSections: [
          {
            path: '#checkbox-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#checkbox-disabled',
            subSectionName: 'Checkbox Disabled',
            subSectionPriority: 2
          },
          {
            path: '#checkbox-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 3
          },
          {
            path: '#checkbox-error',
            subSectionName: 'Checkbox Error',
            subSectionPriority: 4
          },
          {
            path: '#checkbox-group-error',
            subSectionName: 'Group Error',
            subSectionPriority: 5
          }
        ]
      },
      CHECKBOX: {
        path: '/magenta-design-system/components/checkbox',
        subPageName: 'Checkbox',
        subPagePriority: 7,
        subSections: [
          {
            path: '#checkbox-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#checkbox-standard-disabled',
            subSectionName: 'Standard Disabled',
            subSectionPriority: 2
          },
          {
            path: '#checkbox-selected',
            subSectionName: 'Selected',
            subSectionPriority: 3
          },
          {
            path: '#checkbox-selected-disabled',
            subSectionName: 'Selected Disabled',
            subSectionPriority: 4
          },
          {
            path: '#checkbox-indeterminate',
            subSectionName: 'Indeterminate',
            subSectionPriority: 5
          },
          {
            path: '#checkbox-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 6
          },
          {
            path: '#checkbox-error',
            subSectionName: 'Error',
            subSectionPriority: 7
          },
          {
            path: '#checkbox-custom-label',
            subSectionName: 'Custom Label',
            subSectionPriority: 8
          }
        ]
      },
      DATAGRID: {
        path: '/magenta-design-system/components/datagrid',
        subPageName: 'Data Grid',
        subPagePriority: 8,
        subSections: [
          {
            path: '#data-grid-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#data-grid-actions-cell',
            subSectionName: 'Actions Cell',
            subSectionPriority: 2
          },
          {
            path: '#data-grid-checkbox-cell',
            subSectionName: 'Checkbox Cell',
            subSectionPriority: 3
          },
          {
            path: '#data-grid-email-cell',
            subSectionName: 'Email Cell',
            subSectionPriority: 4
          },
          {
            path: '#data-grid-date-cell',
            subSectionName: 'Date Cell',
            subSectionPriority: 5
          },
          {
            path: '#data-grid-graph-cell',
            subSectionName: 'Graph Cell',
            subSectionPriority: 6
          },
          {
            path: '#data-grid-html-cell',
            subSectionName: 'HTML Cell',
            subSectionPriority: 7
          },
          {
            path: '#data-grid-link-cell',
            subSectionName: 'Link Cell',
            subSectionPriority: 8
          },
          {
            path: '#data-grid-number-cell',
            subSectionName: 'Number Cell',
            subSectionPriority: 9
          },
          {
            path: '#data-grid-select-cell',
            subSectionName: 'Select Cell',
            subSectionPriority: 10
          },
          {
            path: '#data-grid-tags-cell',
            subSectionName: 'Tags Cell',
            subSectionPriority: 11
          },
          {
            path: '#data-grid-telephone-cell',
            subSectionName: 'Telephone Cell',
            subSectionPriority: 12
          },
          {
            path: '#data-grid-text-cell',
            subSectionName: 'Text Cell',
            subSectionPriority: 13
          },
          {
            path: '#data-grid-freeze-header',
            subSectionName: 'Freeze Header And Height',
            subSectionPriority: 14
          },
          {
            path: '#data-grid-heading',
            subSectionName: 'Heading',
            subSectionPriority: 15
          },
          {
            path: '#data-grid-hide-extras',
            subSectionName: 'Hide Extras',
            subSectionPriority: 16
          },
          {
            path: '#data-grid-numbered',
            subSectionName: 'Numbered',
            subSectionPriority: 16
          },
          {
            path: '#data-grid-pagination',
            subSectionName: 'Pagination',
            subSectionPriority: 16
          },
          {
            path: '#data-grid-selection-export',
            subSectionName: 'Selection Export',
            subSectionPriority: 17
          },
          {
            path: '#data-grid-column-stretch',
            subSectionName: 'Column Stretch',
            subSectionPriority: 18
          },
          {
            path: '#data-grid-show-more',
            subSectionName: 'Show More',
            subSectionPriority: 19
          }
        ]
      },
      DATEPICKER: {
        path: '/magenta-design-system/components/datepicker',
        subPageName: 'Datepicker',
        subPagePriority: 9,
        subSections: [
          {
            path: '#datepicker-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#datepicker-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 2
          },
          {
            path: '#datepicker-small',
            subSectionName: 'Small',
            subSectionPriority: 3
          },
          {
            path: '#datepicker-with-error',
            subSectionName: 'With Error',
            subSectionPriority: 4
          },
          {
            path: '#datepicker-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 5
          },
          {
            path: '#datepicker-localization',
            subSectionName: 'Localization',
            subSectionPriority: 6
          },
          {
            path: '#datepicker-date-range-picker',
            subSectionName: 'Date Range Picker',
            subSectionPriority: 7
          }
        ]
      },
      DIVIDER: {
        path: '/magenta-design-system/components/divider',
        subPageName: 'Divider',
        subPagePriority: 10,
        subSections: [
          {
            path: '#divider-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#divider-vertical',
            subSectionName: 'Vertical',
            subSectionPriority: 2
          }
        ]
      },
      DROPDOWN: {
        path: '/magenta-design-system/components/dropdown',
        subPageName: 'Dropdown',
        subPagePriority: 11,
        subSections: [
          {
            path: '#dropdown-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#dropdown-small',
            subSectionName: 'Small',
            subSectionPriority: 2
          },
          {
            path: '#dropdown-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 3
          },
          {
            path: '#dropdown-error',
            subSectionName: 'Error',
            subSectionPriority: 4
          },
          {
            path: '#dropdown-with-custom-icon',
            subSectionName: 'With Custom Icon',
            subSectionPriority: 5
          }
        ]
      },
      FLYOUTMENU: {
        path: '/magenta-design-system/components/flyout-menu',
        subPageName: 'Flyout Menu',
        subPagePriority: 12,
        subSections: [
          {
            path: '#flyout-menu-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#flyout-menu-cascading-menu',
            subSectionName: 'Cascading Menu',
            subSectionPriority: 2
          },
          {
            path: '#flyout-menu-checked-toggled',
            subSectionName: 'Checked Toggle',
            subSectionPriority: 3
          },
          {
            path: '#flyout-menu-brand-header-primary-navigation',
            subSectionName: 'Brand Header Primary Navigation',
            subSectionPriority: 4
          }
        ]
      },
      FOOTER: {
        path: '/magenta-design-system/components/footer',
        subPageName: 'Footer',
        subPagePriority: 13,
        subSections: [
          {
            path: '#footer-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#footer-minimal',
            subSectionName: 'Minimal',
            subSectionPriority: 2
          },
          {
            path: '#footer-custom-slots',
            subSectionName: 'Custom Slots',
            subSectionPriority: 3
          }
        ]
      },
      ICON: {
        path: '/magenta-design-system/components/icon',
        subPageName: 'Icon',
        subPagePriority: 14,
        subSections: [
          {
            path: '#icon-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#icon-with-path-attribute',
            subSectionName: 'With Path Attribute',
            subSectionPriority: 2
          },
          {
            path: '#icon-with-name-attribute',
            subSectionName: 'With Name Attribute',
            subSectionPriority: 3
          },
          {
            path: '#icon-library',
            subSectionName: 'Icon Library',
            subSectionPriority: 4
          }
        ]
      },
      LINK: {
        path: '/magenta-design-system/components/link',
        subPageName: 'Link',
        subPagePriority: 15,
        subSections: [
          {
            path: '#link-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#link-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 2
          },
          {
            path: '#link-with-icon',
            subSectionName: 'With Icon',
            subSectionPriority: 3
          },
          {
            path: '#link-no-underline',
            subSectionName: 'No Underline',
            subSectionPriority: 4
          }
        ]
      },
      LIST: {
        path: '/magenta-design-system/components/list',
        subPageName: 'List',
        subPagePriority: 16,
        subSections: [
          {
            path: '#list-ordered',
            subSectionName: 'Ordered',
            subSectionPriority: 1
          },
          {
            path: '#list-unordered',
            subSectionName: 'Unordered',
            subSectionPriority: 2
          },
          {
            path: '#list-unordered-with-custom-icon',
            subSectionName: 'Unordered With Custom Icon',
            subSectionPriority: 3
          }
        ]
      },
      LOADINGSPINNER: {
        path: '/magenta-design-system/components/loading-spinner',
        subPageName: 'Loading-Spinner',
        subPagePriority: 17,
        subSections: [
          {
            path: '#spinner-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#spinner-small-primary-horizontal',
            subSectionName: 'Small Primary (horizontal)',
            subSectionPriority: 2
          },
          {
            path: '#spinner-small-primary-vertical',
            subSectionName: 'Small Primary (Vertical)',
            subSectionPriority: 3
          },
          {
            path: '#spinner-large-primary-horizontal',
            subSectionName: 'Large Primary(horizontal)',
            subSectionPriority: 4
          },
          {
            path: '#spinner-large-primary-vertical',
            subSectionName: 'Large Primary (Vertical)',
            subSectionPriority: 5
          },
          {
            path: '#spinner-small-white',
            subSectionName: 'Small White',
            subSectionPriority: 6
          },
          {
            path: '#spinner-small-white-horizontal',
            subSectionName: 'Small White (horizontal)',
            subSectionPriority: 7
          },
          {
            path: '#spinner-small-white-vertical',
            subSectionName: 'Small White (Vertical)',
            subSectionPriority: 8
          },
          {
            path: '#spinner-large-white',
            subSectionName: 'Large White',
            subSectionPriority: 9
          },
          {
            path: '#spinner-large-white-horizontal',
            subSectionName: 'Large White (horizontal)',
            subSectionPriority: 10
          },
          {
            path: '#spinner-large-white-vertical',
            subSectionName: 'Large White (Vertical)',
            subSectionPriority: 11
          }
        ]
      },
      LOGO: {
        path: '/magenta-design-system/components/logo',
        subPageName: 'Logo',
        subPagePriority: 18,
        subSections: [
          {
            path: '#logo-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#logo-white',
            subSectionName: 'White',
            subSectionPriority: 2
          },
          {
            path: '#logo-sizing',
            subSectionName: 'Sizing',
            subSectionPriority: 3
          },
          {
            path: '#logo-link',
            subSectionName: 'Link',
            subSectionPriority: 4
          },
          {
            path: '#logo-macedonia-cyrillic',
            subSectionName: 'Macedonia Cyrillic',
            subSectionPriority: 5
          },
          {
            path: '#logo-macedonia-latin-script',
            subSectionName: 'Macedonia Latin Script',
            subSectionPriority: 6
          }
        ]
      },
      MODAL: {
        path: '/magenta-design-system/components/modal',
        subPageName: 'Modal',
        subPagePriority: 19,
        subSections: [
          {
            path: '#modal-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#modal-small',
            subSectionName: 'Small',
            subSectionPriority: 2
          },
          {
            path: '#modal-standard-large',
            subSectionName: 'Standard Large',
            subSectionPriority: 3
          },
          {
            path: '#modal-no-action',
            subSectionName: 'No Actions',
            subSectionPriority: 4
          },
          {
            path: '#modal-with-no-body',
            subSectionName: 'With No Body',
            subSectionPriority: 5
          },
          {
            path: '#modal-scrolling-with-actions',
            subSectionName: 'Scrolling With Actions',
            subSectionPriority: 6
          }
        ]
      },
      PAGINATION: {
        path: '/magenta-design-system/components/pagination',
        subPageName: 'Pagination',
        subPagePriority: 20,
        subSections: [
          {
            path: '#pagination-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#pagination-small',
            subSectionName: 'Small',
            subSectionPriority: 2
          },
          {
            path: '#pagination-hidden-borders',
            subSectionName: 'Hidden Borders',
            subSectionPriority: 3
          },
          {
            path: '#pagination-embedded-hidden-borders',
            subSectionName: 'Embedded Hidden Borders',
            subSectionPriority: 4
          },
          {
            path: '#pagination-translate-aria-labels',
            subSectionName: 'Translate Aria Labels',
            subSectionPriority: 5
          }
        ]
      },
      PROGRESSBAR: {
        path: '/magenta-design-system/components/progress-bar',
        subPageName: 'Progress Bar',
        subPagePriority: 20,
        subSections: [
          {
            path: '#progress-bar-determinate',
            subSectionName: 'Determinate',
            subSectionPriority: 1
          },
          {
            path: '#progress-bar-custom',
            subSectionName: 'Progress Custom',
            subSectionPriority: 2
          },
          {
            path: '#progress-bar-with-description',
            subSectionName: 'Progress with description',
            subSectionPriority: 3
          },
          {
            path: '#progress-bar-complete-default',
            subSectionName: 'Complete Default',
            subSectionPriority: 4
          },
          {
            path: '#progress-bar-complete-custom-color',
            subSectionName: 'Complete Custom Color',
            subSectionPriority: 5
          },
          {
            path: '#progress-bar-complete-error',
            subSectionName: 'Complete Error',
            subSectionPriority: 6
          },
          {
            path: '#progress-bar-interactive',
            subSectionName: 'Interactive',
            subSectionPriority: 7
          }
        ]
      },
      RADIOBUTTONGROUP: {
        path: '/magenta-design-system/components/radio-button-group',
        subPageName: 'Radio Button Group',
        subPagePriority: 22,
        subSections: [
          {
            path: '#radio-button-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#radio-button-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 2
          },
          {
            path: '#radio-button-error',
            subSectionName: 'Error',
            subSectionPriority: 3
          }
        ]
      },
      RADIOBUTTON: {
        path: '/magenta-design-system/components/radio-button',
        subPageName: 'Radio Button',
        subPagePriority: 23,
        subSections: [
          {
            path: '#radio-button-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#radio-button-standard-disabled',
            subSectionName: 'Standard Disabled',
            subSectionPriority: 2
          },
          {
            path: '#radio-button-selected',
            subSectionName: 'Selected',
            subSectionPriority: 3
          },
          {
            path: '#radio-button-selected-disabled',
            subSectionName: 'Selected Disabled',
            subSectionPriority: 4
          },
          {
            path: '#radio-button-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 5
          },
          {
            path: '#radio-button-error',
            subSectionName: 'Error',
            subSectionPriority: 6
          }
        ]
      },
      RATINGSTARS: {
        path: '/magenta-design-system/components/rating-stars',
        subPageName: 'Rating Stars',
        subPagePriority: 24,
        subSections: [
          {
            path: '#rating-stars-info-text-and-custom-label',
            subSectionName: 'Info Text and custom Label',
            subSectionPriority: 1
          },
          {
            path: '#rating-stars-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 2
          },
          {
            path: '#rating-stars-small-with-info-text',
            subSectionName: 'Small With Info Text',
            subSectionPriority: 3
          },
          {
            path: '#rating-stars-aria-label-translation',
            subSectionName: 'Aria Label Translation',
            subSectionPriority: 4
          },
          {
            path: '#rating-stars-hidden-label',
            subSectionName: 'Hidden Label',
            subSectionPriority: 5
          },
          {
            path: '#rating-stars-readonly',
            subSectionName: 'Readonly',
            subSectionPriority: 6
          }
        ]
      },
      SIDEBARNAVIGATION: {
        path: '/magenta-design-system/components/sidebar-navigation',
        subPageName: 'Sidebar Navigation',
        subPagePriority: 25,
        subSections: [
          {
            path: '#sidebar-navigation-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#sidebar-navigation-active-on-level-1',
            subSectionName: 'Active on Level 1',
            subSectionPriority: 2
          },
          {
            path: '#sidebar-navigation-active-on-level-2',
            subSectionName: 'Active on Level 2',
            subSectionPriority: 3
          },
          {
            path: '#sidebar-navigation-custom-media-query',
            subSectionName: 'Custom Media Query',
            subSectionPriority: 4
          }
        ]
      },
      SLIDER: {
        path: '/magenta-design-system/components/slider',
        subPageName: 'Slider',
        subPagePriority: 26,
        subSections: [
          {
            path: '#slider-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#slider-track-small',
            subSectionName: 'Slider track small',
            subSectionPriority: 2
          },
          {
            path: '#slider-thumb-large',
            subSectionName: 'Slider thumb large',
            subSectionPriority: 3
          },
          {
            path: '#slider-with-custom-color',
            subSectionName: 'Slider with custom color',
            subSectionPriority: 4
          },
          {
            path: '#slider-disabled',
            subSectionName: 'Disabled slider',
            subSectionPriority: 5
          }
        ]
      },
      SWITCH: {
        path: '/magenta-design-system/components/switch',
        subPageName: 'Switch',
        subPagePriority: 27,
        subSections: [
          {
            path: '#switch-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#switch-disabled',
            subSectionName: 'Standard disabled',
            subSectionPriority: 2
          },
          {
            path: '#switch-selected',
            subSectionName: 'Selected',
            subSectionPriority: 3
          },
          {
            path: '#switch-selected-disabled',
            subSectionName: 'Selected disabled',
            subSectionPriority: 4
          }
        ]
      },
      TABNAVIGATION: {
        path: '/magenta-design-system/components/tab-navigation',
        subPageName: 'Tab Navigation',
        subPagePriority: 28,
        subSections: [
          {
            path: '#tab-navigation-text-icon',
            subSectionName: 'Text & Icon',
            subSectionPriority: 1
          },
          {
            path: '#tab-navigation-text-icon-small',
            subSectionName: 'Text & Icon, Small',
            subSectionPriority: 2
          },
          {
            path: '#tab-navigation-text-only',
            subSectionName: 'Text Only',
            subSectionPriority: 3
          },
          {
            path: '#tab-navigation-text-only-small',
            subSectionName: 'Text Only, Small',
            subSectionPriority: 4
          },
          {
            path: '#tab-navigation-disabled-tabs',
            subSectionName: 'Disabled Tabs',
            subSectionPriority: 5
          }
        ]
      },
      TABLE: {
        path: '/magenta-design-system/components/table',
        subPageName: 'Table',
        subPagePriority: 29,
        subSections: [
          {
            path: '#table-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#table-small',
            subSectionName: 'Small',
            subSectionPriority: 2
          },
          {
            path: '#table-with-sorting-icons',
            subSectionName: 'With Sorting Icons',
            subSectionPriority: 3
          },
          {
            path: '#table-with-striped-rows',
            subSectionName: 'With Striped Rows',
            subSectionPriority: 4
          }
        ]
      },
      TAG: {
        path: '/magenta-design-system/components/tag',
        subPageName: 'Tag',
        subPagePriority: 30,
        subSections: [
          {
            path: '#tag-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#tag-a-dissmisable-tag',
            subSectionName: 'A dissmisable tag',
            subSectionPriority: 2
          },
          {
            path: '#tag-a-small-tag',
            subSectionName: 'A small tag',
            subSectionPriority: 3
          },
          {
            path: '#tag-a-small-dismissable-tag',
            subSectionName: 'A small dismissable tag',
            subSectionPriority: 4
          },
          {
            path: '#tag-disabled-dismissable-tag',
            subSectionName: 'Disable dissmisable tag',
            subSectionPriority: 5
          },
          {
            path: '#tag-variant-secondary-tag',
            subSectionName: 'Variant secondary tag',
            subSectionPriority: 6
          },
          {
            path: '#tag-variant-secondary-link',
            subSectionName: 'Variant secondary link',
            subSectionPriority: 7
          },
          {
            path: '#tag-variant-secondary-dismissable',
            subSectionName: 'Variant secondary dismissable',
            subSectionPriority: 8
          },
          {
            path: '#tag-variant-secondary-small',
            subSectionName: 'Variant secondary small',
            subSectionPriority: 9
          },
          {
            path: '#tag-variant-secondary-dismissable-small',
            subSectionName: 'Variant secondary dismissable small',
            subSectionPriority: 10
          },
          {
            path: '#tag-complete-example',
            subSectionName: 'Complete example',
            subSectionPriority: 11
          }
        ]
      },
      TEXTAREA: {
        path: '/magenta-design-system/components/textarea',
        subPageName: 'Text Area',
        subPagePriority: 31,
        subSections: [
          {
            path: '#text-area-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#text-area-placeholder',
            subSectionName: 'Placeholder',
            subSectionPriority: 2
          },
          {
            path: '#text-area-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 3
          },
          {
            path: '#text-area-with-error',
            subSectionName: 'With Error',
            subSectionPriority: 4
          },
          {
            path: '#text-area-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 5
          },
          {
            path: '#text-area-max-length-with-counter',
            subSectionName: 'Max Length With Counter',
            subSectionPriority: 6
          },
          {
            path: '#text-area-more-rows',
            subSectionName: 'More rows',
            subSectionPriority: 7
          }
        ]
      },
      TEXTFIELD: {
        path: '/magenta-design-system/components/textfield',
        subPageName: 'TextField',
        subPagePriority: 32,
        subSections: [
          {
            path: '#text-field-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#text-field-text-with-events',
            subSectionName: 'Text with Events',
            subSectionPriority: 2
          },
          {
            path: '#text-field-placeholder',
            subSectionName: 'Placeholder',
            subSectionPriority: 3
          },
          {
            path: '#text-field-helper-text',
            subSectionName: 'Helper Text',
            subSectionPriority: 4
          },
          {
            path: '#text-field-small',
            subSectionName: 'Small',
            subSectionPriority: 5
          },
          {
            path: '#text-field-with-error',
            subSectionName: 'With Error',
            subSectionPriority: 6
          },
          {
            path: '#text-field-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 7
          },
          {
            path: '#text-field-read-only',
            subSectionName: 'Read Only',
            subSectionPriority: 8
          },
          {
            path: '#text-field-max-length-with-counter',
            subSectionName: 'Max Length With Counter',
            subSectionPriority: 8
          }
        ]
      }
    }
  },
  BETACOMPONENTS: {
    path: '/magenta-design-system/components',
    leftNavName: 'Beta Components',
    showArrow: true,
    isHeading: true,
    leftNavPriority: 4,
    subPages: {
      CALLOUT: {
        path: '/magenta-design-system/beta-components/callout',
        subPageName: 'Callout',
        subPagePriority: 1,
        subSections: [
          {
            path: '#callout-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#callout-size',
            subSectionName: 'Size',
            subSectionPriority: 2
          },
          {
            path: '#callout-rotation',
            subSectionName: 'Rotation',
            subSectionPriority: 3
          },
          {
            path: '#callout-variant',
            subSectionName: 'Variant',
            subSectionPriority: 4
          },
          {
            path: '#callout-asterisk',
            subSectionName: 'Asterisk',
            subSectionPriority: 5
          }
        ]
      },
      NOTIFICATIONBADGE: {
        path: '/magenta-design-system/beta-components/notification-badge',
        subPageName: 'Notification Badge',
        subPagePriority: 1,
        subSections: [
          {
            path: '#notification-badge-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#notification-badge-icon',
            subSectionName: 'Icon',
            subSectionPriority: 2
          },
          {
            path: '#notification-badge-text',
            subSectionName: 'Text',
            subSectionPriority: 3
          },
          {
            path: '#notification-badge-label-icon',
            subSectionName: 'Label Icon',
            subSectionPriority: 4
          },
          {
            path: '#notification-badge-label-text',
            subSectionName: 'Label Text',
            subSectionPriority: 5
          },
          {
            path: '#notification-badge-custom_navigation',
            subSectionName: 'Brand Header Custom Icon Navigation',
            subSectionPriority: 6
          },
          {
            path: '#notification-badge-usermenu_navigation',
            subSectionName: 'Brand Header User Menu & Icon Navigation',
            subSectionPriority: 7
          }
        ]
      },
      NOTIFICATIONBANNER: {
        path: '/magenta-design-system/beta-components/notification-banner',
        subPageName: 'Notification Banner',
        subPagePriority: 1,
        subSections: [
          {
            path: '#notification-banner-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#notification-banner-warning',
            subSectionName: 'Warning',
            subSectionPriority: 2
          },
          {
            path: '#notification-banner-error',
            subSectionName: 'Error',
            subSectionPriority: 3
          },
          {
            path: '#notification-banner-success',
            subSectionName: 'Success',
            subSectionPriority: 4
          },
          {
            path: '#notification-banner-informational',
            subSectionName: 'Informational',
            subSectionPriority: 5
          },
          {
            path: '#notification-banner-dismissible',
            subSectionName: 'Dismissible',
            subSectionPriority: 6
          },
          {
            path: '#notification-banner-timeout',
            subSectionName: 'Timeout',
            subSectionPriority: 7
          },
          {
            path: '#notification-banner-with-text',
            subSectionName: 'With Text',
            subSectionPriority: 8
          },
          {
            path: '#notification-banner-with-link',
            subSectionName: 'With Link',
            subSectionPriority: 9
          },
          {
            path: '#notification-banner-with-textlink',
            subSectionName: 'With Link and Text',
            subSectionPriority: 10
          }
        ]
      },
      NOTIFICATIONMESSAGE: {
        path: '/magenta-design-system/beta-components/notification-message',
        subPageName: 'Notification Message',
        subPagePriority: 1,
        subSections: [
          {
            path: '#notification-message-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#notification-message-warning',
            subSectionName: 'Warning',
            subSectionPriority: 2
          },
          {
            path: '#notification-message-error',
            subSectionName: 'Error',
            subSectionPriority: 3
          },
          {
            path: '#notification-message-success',
            subSectionName: 'Success',
            subSectionPriority: 4
          },
          {
            path: '#notification-message-informational',
            subSectionName: 'Informational',
            subSectionPriority: 5
          },
          {
            path: '#notification-message-dismissible',
            subSectionName: 'Dismissible',
            subSectionPriority: 6
          },
          {
            path: '#notification-message-timeout',
            subSectionName: 'Timeout',
            subSectionPriority: 7
          },
          {
            path: '#notification-message-with-text',
            subSectionName: 'With Text',
            subSectionPriority: 8
          }
        ]
      },
      NOTIFICATIONTOAST: {
        path: '/magenta-design-system/beta-components/notification-toast',
        subPageName: 'Notification Toast',
        subPagePriority: 1,
        subSections: [
          {
            path: '#notification-toast-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#notification-toast-warning',
            subSectionName: 'Warning',
            subSectionPriority: 2
          },
          {
            path: '#notification-toast-error',
            subSectionName: 'Error',
            subSectionPriority: 3
          },
          {
            path: '#notification-toast-success',
            subSectionName: 'Success',
            subSectionPriority: 4
          },
          {
            path: '#notification-toast-informational',
            subSectionName: 'Informational',
            subSectionPriority: 5
          },
          {
            path: '#notification-toast-timeout',
            subSectionName: 'Timeout',
            subSectionPriority: 6
          },
          {
            path: '#notification-toast-with-text',
            subSectionName: 'With Text',
            subSectionPriority: 7
          },
          {
            path: '#notification-toast-with-link',
            subSectionName: 'With Link',
            subSectionPriority: 8
          }
        ]
      },
      TOGGLEGROUP: {
        path: '/magenta-design-system/beta-components/toggle-group',
        subPageName: 'Toggle Group',
        subPagePriority: 1,
        subSections: [
          {
            path: '#toggle-group-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#toggle-group-monochrome',
            subSectionName: 'Monochrome Variant',
            subSectionPriority: 2
          },
          {
            path: '#toggle-group-gray-bg',
            subSectionName: 'Gray Background',
            subSectionPriority: 3
          },
          {
            path: '#toggle-no-border',
            subSectionName: 'No Border',
            subSectionPriority: 4
          },
          {
            path: '#toggle-group-small-size',
            subSectionName: 'Small Size',
            subSectionPriority: 5
          },
          {
            path: '#toggle-group-full-width',
            subSectionName: 'Full width',
            subSectionPriority: 6
          },
          {
            path: '#toggle-group-single-select',
            subSectionName: 'Single Select',
            subSectionPriority: 7
          },
          {
            path: '#toggle-group-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 8
          },
          {
            path: '#toggle-group-accessibility',
            subSectionName: 'Accessibility German',
            subSectionPriority: 9
          },
          {
            path: '#toggle-group-icon-before',
            subSectionName: 'Icon Before',
            subSectionPriority: 10
          },
          {
            path: '#toggle-group-icon-only',
            subSectionName: 'Icon Only',
            subSectionPriority: 11
          }
        ]
      },
      TOOLTIP: {
        path: '/magenta-design-system/beta-components/tooltip',
        subPageName: 'Tooltip',
        subPagePriority: 1,
        subSections: [
          {
            path: '#tooltip-standard',
            subSectionName: 'Standard',
            subSectionPriority: 1
          },
          {
            path: '#tooltip-placement-left',
            subSectionName: 'Placement Left',
            subSectionPriority: 2
          },
          {
            path: '#tooltip-placement-right',
            subSectionName: 'Placement Right',
            subSectionPriority: 3
          },
          {
            path: '#tooltip-placement-below',
            subSectionName: 'Below',
            subSectionPriority: 4
          },
          {
            path: '#tooltip-trigger',
            subSectionName: 'Trigger',
            subSectionPriority: 5
          },
          {
            path: '#tooltip-slot',
            subSectionName: 'Slot',
            subSectionPriority: 6
          },
          {
            path: '#tooltip-disabled',
            subSectionName: 'Disabled',
            subSectionPriority: 7
          }
        ]
      }
    }
  }
};

export const TITLES = {
  MAGENTA_DESIGN_SYSTEM: 'Magenta Design System'
};

export const THEMES = {
  THEME_LIGHT: 'light',
  THEME_DARK: 'dark'
};
