import {
  APP_MOBILE,
  APP_ROLES_MAPPING,
  HTTP_ERRORS,
  SUA_PAGES,
  SUA_ROLES
} from './app_constants';
import moment from 'moment';
import Highcharts from 'highcharts';
import FileSaver from 'file-saver';
import _ from 'underscore';
import { prettifyCamelCase } from './stringUtils';

export const parseHTTPErrorCode = code => {
  let message = HTTP_ERRORS[String(code)];

  if (!message) {
    message = 'You caught us at a bad time. Please try again later.';
  }

  return message;
};

export const generateQueryString = queryParams => {
  let queryString = '';

  if (queryParams.length > 0) {
    queryString += `${queryParams[0].key}=${queryParams[0].value}`;

    for (let i = 1; i < queryParams.length; i++) {
      queryString += `&${queryParams[i].key}=${queryParams[i].value}`;
    }
  }

  return queryString;
};

export const removeDuplicateObject = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const getEnabledProducts = groups => {
  const productGroups = [];
  groups.forEach((group, index) => {
    if (APP_ROLES_MAPPING.PETS.includes(group)) {
      productGroups.push({ value: APP_MOBILE.PETS, enabled: true });
    }
    if (APP_ROLES_MAPPING.TRACKER.includes(group)) {
      productGroups.push({ value: APP_MOBILE.TRACKER, enabled: true });
    }
    if (APP_ROLES_MAPPING.WATCH.includes(group)) {
      productGroups.push({ value: APP_MOBILE.WATCH, enabled: true });
    }
    if (APP_ROLES_MAPPING.DRIVE.includes(group)) {
      productGroups.push({ value: APP_MOBILE.DRIVE, enabled: true });
    }
    if (APP_ROLES_MAPPING.FAMILYLOCATION.includes(group)) {
      productGroups.push({ value: APP_MOBILE.FAMILYLOCATION, enabled: true });
    } else if (APP_ROLES_MAPPING.PETS_TRACKER_WATCH_DRIVE.includes(group)) {
      productGroups.push({ value: APP_MOBILE.PETS, enabled: true });
      productGroups.push({ value: APP_MOBILE.WATCH, enabled: true });
      productGroups.push({ value: APP_MOBILE.TRACKER, enabled: true });
      productGroups.push({ value: APP_MOBILE.DRIVE, enabled: true });
      productGroups.push({ value: APP_MOBILE.FAMILYLOCATION, enabled: true });
    } else {
    }
  });
  return removeDuplicateObject(productGroups, 'value');
};

export const getProductsStatus = groups => {
  const uniqueArr = getEnabledProducts(groups);
  return {
    options: Object.values(APP_MOBILE).map(v => ({
      value: v,
      disabled: uniqueArr.filter(product => v === product.value).length <= 0
    }))
  };
};

export const getHomePageUrl = (userGroups = []) => {
  if (
    userGroups.includes(SUA_ROLES.ADMIN) ||
    userGroups.includes(SUA_ROLES.CARE) ||
    userGroups.includes(SUA_ROLES.TRACKER_ADMIN) ||
    userGroups.includes(SUA_ROLES.TRACKER_DEV) ||
    userGroups.includes(SUA_ROLES.TRACKER_PO) ||
    userGroups.includes(SUA_ROLES.PETS_ADMIN) ||
    userGroups.includes(SUA_ROLES.PETS_DEV) ||
    userGroups.includes(SUA_ROLES.PETS_PO) ||
    userGroups.includes(SUA_ROLES.WATCH_ADMIN) ||
    userGroups.includes(SUA_ROLES.WATCH_DEV) ||
    userGroups.includes(SUA_ROLES.WATCH_PO) ||
    userGroups.includes(SUA_ROLES.MOBILE_APP_DEVS) ||
    userGroups.includes(SUA_ROLES.SYNCUP_DPI) // TO-DO Rrmove SYNCUP_DPI AFter Testing
  ) {
    return SUA_PAGES.SEARCH.path;
  } else if (userGroups.includes(SUA_ROLES.PO)) {
    return `${SUA_PAGES.ANALYTICS.subPages.DASHBOARD.path}${SUA_PAGES.ANALYTICS.subPages.DASHBOARD.defaultPage}`;
  } else if (
    userGroups.includes(SUA_ROLES.SUPPLY_CHAIN) ||
    userGroups.includes(SUA_ROLES.ADMIN)
  ) {
    return SUA_PAGES.BULK_OPERATIONS.subPages.BULK_UNCLAIM.path;
  } else if (
    userGroups.includes(SUA_ROLES.SYNCUP_DPI) ||
    userGroups.includes(SUA_ROLES.ADMIN)
  ) {
    return SUA_PAGES.DPI_SUPPORT.path;
  } else if (
    userGroups.includes(SUA_ROLES.TAG_COMMERCIAL_USER) ||
    userGroups.includes(SUA_ROLES.ADMIN)
  ) {
    return SUA_PAGES.COMMERCIAL_TRACKER.path;
  } else if (
    userGroups.includes(SUA_ROLES.TAG_COMMERCIAL_ADMIN) ||
    userGroups.includes(SUA_ROLES.ADMIN)
  ) {
    return SUA_PAGES.COMMERCIAL_TRACKER_USER_MANAGEMENT.path;
  } else if (userGroups.includes(SUA_ROLES.ODM_TRACKER)) {
    return SUA_PAGES.TEST_HARNESS.subPages.TRACKER.path;
  } else if (userGroups.includes(SUA_ROLES.ODM_KIDSWATCH)) {
    return SUA_PAGES.TEST_HARNESS.subPages.KIDSWATCH.path;
  } else if (
    userGroups.includes(SUA_ROLES.FIRMWARE_SUPPORT) ||
    userGroups.includes(SUA_ROLES.PETS_FIRMWARE_SUPPORT) ||
    userGroups.includes(SUA_ROLES.WATCH_FIRMWARE_SUPPORT) ||
    userGroups.includes(SUA_ROLES.DRIVE_FIRMWARE_SUPPORT) ||
    userGroups.includes(SUA_ROLES.TRACKER_FIRMWARE_SUPPORT)
  ) {
    return SUA_PAGES.FIRMWARE.path;
  }
  return SUA_PAGES.ERROR.path;
};

export const parseUserIdFromURL = (url, indexOf) => {
  return url.substring(
    url.lastIndexOf('/users/') + 7,
    url.lastIndexOf('/' + indexOf)
  );
};

export const parseDeviceIdFromURL = (url, indexOf) => {
  return url.substring(
    url.lastIndexOf('/devices/') + 9,
    url.lastIndexOf('/' + indexOf)
  );
};

export const capitalCase = str => {
  if (typeof str === 'string') {
    return str
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  return str;
};

export const removeDashes = str => {
  return str.toLowerCase().replace(/[-_]/gi, ' ');
};

export const chartToolTip = options => {
  return Object.entries(options).map(item => {
    if (Object.prototype.toString.call(item[1]) === '[object Date]') {
      return `<strong>${item[0]}</strong>: ${moment(item[1]).format(
        'MMMM Do, h:mm a'
      )}<br/>`;
    } else {
      return `<strong>${item[0]}</strong>: ${capitalCase(item[1])}<br/>`;
    }
  });
};

export const chartPercentages = (yAxis, total) => {
  let percent = (yAxis / total) * 100;
  return Highcharts.numberFormat(percent, 2, '.', ',') + '%';
};

export const filterBy = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    return filterKeys.every(key => {
      if (!filters[key].length) {
        return true;
      }
      return filters[key].includes(item[key]);
    });
  });
};

export const sortList = (arr, { listItemKey: listItem }, sortDirection) => {
  arr.sort((a, b) => {
    if (sortDirection === 'ASC') {
      if (a[listItem] < b[listItem]) {
        return -1;
      }
      if (a[listItem] > b[listItem]) {
        return 1;
      }
    }

    if (sortDirection === 'DESC') {
      if (b[listItem] < a[listItem]) {
        return -1;
      }
      if (b[listItem] > a[listItem]) {
        return 1;
      }
    }
    return 0;
  });
};

export const countTotal = counts => {
  return counts?.reduce((a, b) => a + b, 0).toFixed();
};

export const countByProp = (arr, classifier) => {
  return arr?.reduce((counter, item) => {
    let p = (classifier || String)(item);
    counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
    return counter;
  }, {});
};

export const celsiusToFarenheit = celsius => {
  return celsius * (9 / 5) + 32;
};

export const getOktaGroups = userInfo => {
  return userInfo?.ThingsPlatformGroups
    ? userInfo.ThingsPlatformGroups
    : userInfo?.Groups;
};

export const hasValue = obj => {
  return !_.isEmpty(obj);
};

export const getSearchedUsers = users => {
  let searchedUsers = users?.map(({ userProfile, applicationProfile }) => ({
    ...userProfile,
    ...applicationProfile
  }));
  searchedUsers =
    searchedUsers.length > 0 && searchedUsers[0].userId != null
      ? searchedUsers
      : users;

  return searchedUsers;
};

export const exportCustomData = ({
  headers = [],
  fileName = '',
  hardwareIds
}) => {
  const processValue = value => {
    if (typeof value === 'object' && value !== null) {
      let stringObj = JSON.stringify(value);
      return `"${stringObj.replace(/"/g, '""')}"`;
    }
    return value ? `"${value}"` : null;
  };

  const csv = `${headers.join(',')}\n${hardwareIds
    .map(hardwareId =>
      headers.map(header => processValue(hardwareId[header])).join(',')
    )
    .join('\n')}`;
  const csvBlob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;'
  });
  FileSaver.saveAs(csvBlob, fileName);
  return { headers, fileName };
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getUserThingBackUrl = (userId, tabName, history) => {
  let userPageUrl = SUA_PAGES.USER.path.replace(':userId', userId);
  let tabUrl = history?.location?.state?.isThingTab ? 'things' : tabName;
  return `${userPageUrl}/${tabUrl}`;
};
export const getGroupsBackUrl = (userId, tabName, history) => {
  let userPageUrl = SUA_PAGES.USER.path.replace(':userId', userId);
  let tabUrl = history?.location?.state?.isThingTab ? 'things' : tabName;
  return `${userPageUrl}/${tabUrl}`;
};
export const shuffleObjectKeysAndValues = obj => {
  const entries = Object.entries(obj);
  for (let i = entries.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [entries[i], entries[j]] = [entries[j], entries[i]];
  }
  let index = 1;
  const shuffledObj = {};
  entries.forEach(([key, value]) => {
    shuffledObj[`${index}.${prettifyCamelCase(value)}`] = key;
    index++;
  });
  return shuffledObj;
};
