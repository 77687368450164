import React, { useCallback, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import userService from 'services/userService';
import { APP_MOBILE, SUA_PAGES } from 'utils/app_constants';
import { getSearchedUsers, hasValue } from 'utils/helper_functions';
import Fetcher from 'components/Fetcher';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import classNames from 'classnames';
import ProductIcon from 'components/uiHelpers/ProductIcon';
import ProductFilter from './ProductFilter';
import ErrorWithImage from 'components/Error/Error';

import style from './UserSearchPanel.module.css';

function UesrSearchResults({ userSearchTerm }) {
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [error, setError] = useState();
  const [filteredUserList, setFilteredUserList] = useState();
  const { setSelectedTab, selectedTab } = useContext(ActiveUserTabContext);
  const { userId, product } = { ...selectedTab };

  const userActionCallback = useCallback(() => {
    setUserSearchResults([]);
    return Promise.allSettled([
      userService.getUsersBySearch({
        q: userSearchTerm,
        syncUpAppName: APP_MOBILE.DRIVE
      }),
      userService.getUsersBySearch({
        q: userSearchTerm,
        syncUpAppName: APP_MOBILE.WATCH
      }),
      userService.getUsersBySearch({
        q: userSearchTerm,
        syncUpAppName: APP_MOBILE.TRACKER
      })
    ]);
  }, [userSearchTerm]);

  const handleLoad = useCallback(data => {
    setError(null);
    let result = [];
    let count = 0;
    data &&
      data.forEach(e => {
        if (e.status === 'fulfilled' && hasValue(e.value)) {
          result = [...result, ...e.value];
        }
        e.status === 'rejected' && count++;
      });
    data.length === count && setError(data[0].reason);
    let searchedUsers = getSearchedUsers(result);
    setUserSearchResults(searchedUsers);
    setFilteredUserList([...searchedUsers]);
  }, []);

  const handleOnFilter = useCallback(data => {
    setFilteredUserList(data);
  }, []);

  const handleClick = user => {
    const pageUrl = SUA_PAGES.USER.path.replace(':userId', user.userId);
    setSelectedTab({
      name: user.firstName,
      userId: user.userId,
      url: `${pageUrl}/things`,
      product: user.product
    });
  };

  return (
    <>
      {userSearchTerm ? (
        <>
          <ProductFilter
            onFilter={handleOnFilter}
            userList={userSearchResults}
          />
          <div className={style.user_result_container} id={style['scrollable']}>
            <Fetcher
              action={userActionCallback}
              onLoad={handleLoad}
              render={() => (
                <>
                  {error && (
                    <ErrorWithImage
                      heading="Failed to fetch"
                      message={error && error.toString()}
                    />
                  )}
                  {filteredUserList &&
                    filteredUserList.map(user => {
                      return (
                        <div
                          key={`${user.userId}-${user.product}`}
                          className={classNames(
                            style.user_card,
                            user.userId === userId &&
                              user.product === product &&
                              style.card_selected
                          )}
                          onClick={() => handleClick(user)}
                        >
                          <div className={style.user_info}>
                            <div className={style.title}>
                              {user?.firstName} {user?.lastName}
                            </div>
                            <div className={style.sub_title}>{user?.email}</div>
                          </div>
                          <div className={style.product_icon}>
                            <ProductIcon product={user.product} />
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            />
          </div>
        </>
      ) : (
        <div className={style.no_result}> </div>
      )}
    </>
  );
}

export default withRouter(UesrSearchResults);
