import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import Callback from './auth/Callback';
import CallbackMsal from 'auth/CallbackMsal';
import Login from './pages/Login';
import Main from './pages/Main';
import Svgs from './components/uiHelpers/Svgs';
import Status from './components/status/Status';
import SyncUpProductContext from './context/SyncUpProductContext';
import DriveLite from './components/poc/drivelite/DriveLite';
import usePersistableState from './hooks/usePersistableState';
import KidswatchPlayStore from './pages/KidswatchPlayStore';
import Error from './pages/Error';
import FaqPage from './pages/hostedApps/faq/FAQPage';
import {
  APP_MOBILE,
  STORAGE_TYPES,
  IS_AZURE_AUTH
} from './utils/app_constants';
import { DESIGN_SYSTEM_ROUTES } from './hostedApps/mds/utils/app_constants';
const DesignSystem = React.lazy(() => import('./hostedApps/mds/pages/Main'));

function App({ oktaBaseUrl, useOktaWidget, instance }) {
  const [syncUpApp, setSyncUpApp] = usePersistableState({
    storageKey: `SyncUp-Product`,
    storageType: STORAGE_TYPES.LOCAL
  });

  const syncUpProduct = {
    syncUpAppName: syncUpApp,
    setSyncUpApp
  };

  const getFAQPath = () => {
    let paths = [];
    Object.entries(APP_MOBILE).forEach(([key, value]) => {
      key === 'WATCH' && value
        ? paths.push({
            path: `/${value}/faq`,
            key: `${key}`,
            component: FaqPage
          })
        : paths.push({
            path: `/${value}/faq`,
            key: `${key}`,
            component: Error
          });
    });
    return paths;
  };

  const routes = (
    <Switch>
      <Route
        path="/login"
        render={() => (
          <Login baseUrl={oktaBaseUrl} isUsingOktaWidget={useOktaWidget} />
        )}
      />
      <Route
        path="/callback"
        component={IS_AZURE_AUTH ? CallbackMsal : Callback}
      />
      <Route path={'/drive'} component={DriveLite} />
      <Route path={'/testharness/drivelite'} component={DriveLite} />
      <Route path={'/kidswatch-app'} component={KidswatchPlayStore} />
      {getFAQPath().map(e => (
        <Route exact key={e.key} path={e.path} component={e.component} />
      ))}
      <Route exact path={DESIGN_SYSTEM_ROUTES} component={DesignSystem} />
      <Route component={Main} />
    </Switch>
  );

  return (
    <SyncUpProductContext.Provider value={syncUpProduct}>
      <Status>
        <Svgs />
        <Suspense fallback={<>...Loading</>}>
          {IS_AZURE_AUTH ? (
            <MsalProvider instance={instance}>{routes}</MsalProvider>
          ) : (
            routes
          )}
        </Suspense>
      </Status>
    </SyncUpProductContext.Provider>
  );
}

export default App;
