import React, { useEffect, startTransition, useState } from 'react';
import { TmoInput } from '@tmobile/tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import ErrorMessageModal from '../../errorHandler/ErrorMessageModal';
import usePersistableState from '../../../hooks/usePersistableState';
import { STORAGE_KEYS, STORAGE_TYPES } from '../../../utils/app_constants';

import style from './UserSearchPanel.module.css';
import searchIcon from '../../../static/assets/icons/help_search.svg';
import close from '../../../static/assets/icons/close_icon.svg';

function UserSearchBar({ onSerachTerm }) {
  const [errorMessage, setErrorMessage] = useState();
  const [searchInput, setSearchInput] = usePersistableState({
    storageKey: STORAGE_KEYS.USER_SEARCH_INPUT,
    storageType: STORAGE_TYPES.SESSION
  });

  const handleClose = () => {
    setSearchInput('');
    onSerachTerm('');
  };
  const handleOnErrorClose = () => {
    setErrorMessage(null);
  };

  const handleErrorOccurred = message => {
    setErrorMessage(message);
  };

  const handleOnSearch = event => {
    event.preventDefault();

    if (!searchInput) {
      handleErrorOccurred('Please input a search term');
      return;
    }
    onSerachTerm(searchInput);
  };

  useEffect(() => {
    if (searchInput) {
      startTransition(() => {
        onSerachTerm(searchInput);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {errorMessage && (
        <ErrorMessageModal
          message={errorMessage}
          onClose={handleOnErrorClose}
        />
      )}

      <form onSubmit={handleOnSearch} className={style.search_section}>
        <ReactSVG src={searchIcon} className={style.search_icon} />
        <TmoInput
          className={style.search_input}
          size="lg"
          placeholder="Search users"
          onChange={setSearchInput}
          value={searchInput}
          inputType="primary"
        />
        {searchInput && (
          <ReactSVG
            onClick={() => handleClose()}
            className={style.close_icon}
            src={close}
          />
        )}
      </form>
    </>
  );
}

export default UserSearchBar;
