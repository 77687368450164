import React, { useState } from 'react';

import UesrSearchResults from './UerSearchResult';
import UserSearchBar from './UserSearchBar';

import style from './UserSearchPanel.module.css';

function SearchPanel() {
  const [searchTerm, setSearchTerm] = useState();
  const onSerachTerm = text => {
    setSearchTerm(text);
  };
  return (
    <>
      <h1 className={style.panel_title}> User Search </h1>
      <UserSearchBar onSerachTerm={onSerachTerm} />
      <UesrSearchResults userSearchTerm={searchTerm} />
    </>
  );
}
export default SearchPanel;
